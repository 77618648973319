const tutorialSteps = [
    {
        label: 'Gaachwala',
        link: 'http://www.gaachwala.com/',
        imgPath: 'https://i.ibb.co/7Qmp9xN/Gaachwala.png',
        des: 'Gaachwala is an e-commerce platform dedicated to support and develop greener world.',
        detail: 'There is a shift that must happen within. Something clicks-in and your brain and biology can never go back. Ralph Waldo Emerson said, The mind, once stretched by a new idea, never returns to its original dimensions. Ideas can only truly catch hold if they are embedded within a deeply emotional experience that reshapes how you view your identity and purpose in life. When an idea becomes your obsession and purpose — and it is highly personal to you — then you can take that idea and quickly turn it into a reality.',
    },
    {
        label: 'Khelahobe!',
        link: 'http://www.khelahobe.co/',
        imgPath: 'https://i.ibb.co/nn8s92Y/Khelahobe.png',
        des: 'Khelahobe is all about the game of cricket. It is committed to provide real time updates and stories of local and international matches in Bangla.',
        //detail: text2,
    },
    {
        label: 'Ardent Programmers Website',
        link: 'https://ardentprogrammers.com/',
        imgPath: 'https://i.ibb.co.com/7CzbJjg/Screenshot-from-2024-12-11-17-21-05.png',
        des: `Home of Bangladesh's best Problem Setting Platform.`,
        //detail: text3,
    },
    {
        label: 'Kotthyo',
        link: 'https://kotthyo.web.app/',
        imgPath: 'https://i.ibb.co.com/X5sbsLs/Screenshot-from-2024-12-11-22-11-25.png',
        des: `A website for translating parallel sentence from English to Bengali.`,
        //detail: text3,
    },
    {
        label: 'Horkora',
        link: 'https://horkora.web.app/',
        imgPath: 'https://i.ibb.co.com/YDvPNZQ/Screenshot-from-2024-12-11-22-20-26.png',
        des: `A food delivery app with admin and restaurant website`,
        //detail: text3,
    },
    {
        label: 'Skinfinity',
        link: '',
        imgPath: 'https://i.ibb.co/9gRQxGs/skinfinity.png',
        des: `A portfolio site for the leading Skin Care solution in South Bengal.`,
        //detail: text3,
    },
    {
        label: 'PromptSource',
        link: 'https://github.com/TarifEzaz/promptsource',
        imgPath: 'https://i.ibb.co/kh1qGnr/promptsource.png',
        des: `PromptSource is a toolkit for creating, sharing and using natural language prompts. We have contributed to two datasets so far and created prompts in 15 different languages.`,
        //detail: text3,
    },
    {
        label: 'Love Beyond Autism',
        link: 'https://www.lovebeyondautism.com/',
        imgPath: 'https://i.ibb.co/3Bngjr0/LBA.png',
        des: `Love Beyond Autism is a dating site for autistic individuals. We worked on the previous version of this project`,
        //detail: text3,
    },
    {
        label: 'Suit & Sarto',
        link: '',
        imgPath: 'https://i.ibb.co/6PMxZDP/Suitsarto.jpg',
        des: `Suit and Sarto is an e-commerce platform for men's wear.`,
        //detail: text3,
    },
];

export default tutorialSteps;